.social-container {
    background-color: #000;
    /* padding: 4rem 0 2rem 0; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-inline-color: red;
  }

  .social-ig-media-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
    background-color: #000;
  }
  
  /* Social Icons */
  .social-icon-link {
    color: #fff;
    font-size: 24px;
  }
  
  .social-media {
    max-width: 1000px;
    width: 100%;
  }
  
 

  .ig-icon{
    color: #fff !important;
    font-size: 75px;
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 240px;
  }
  
  .social-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
  }
  
  .website-rights {
    color: #fff;
    margin-bottom: 16px;
    font-size: 20px;
  }
  
  @media screen and (max-width: 820px) {
    .footer-links {
      padding-top: 2rem;
    }
  
    .footer-input {
      width: 100%;
    }
  
    .btn {
      width: 100%;
    }
  
    .footer-link-wrapper {
      flex-direction: column;
    }
  
    .social-ig-media-wrap {
      flex-direction: column;
      background: color black
    }
  }
  
  @media screen and (max-width: 768px) {
  }
  