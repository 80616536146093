* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'PT Sans', sans-serif;
  
}

.home,
.contact,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.about {
  /* background-image: url('/images/img-2.jpg'); */
  background-color: #000000;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.contact {
  /* background-image: url('/images/img-1.jpg'); */
  background-color: #000000;
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  flex-direction: column;

}

.secret-head {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Set the height to full viewport height */
  background-color: #000; /* Set the background color */
  color: #000; /* Set text color */ 
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.test{
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 90%; */
  max-width: 1000px;
  /* margin: 40px auto 0 auto; */
  /* background-color: white; */
  flex-direction: column;
}
/* 
@media screen and (max-width: 320px) {
  .about .secret-head{
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }

} */
@media screen and (max-width: 2000px) {
  .contact{
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 5rem;
  }
}


@media screen and (max-width: 1700px) {
  .contact{
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
}


@media screen and (max-width: 1400px) {
  .contact{
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
  }
}


@media screen and (max-width: 960px) {
  .contact{
    height: 100vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
}

@media screen and (max-width: 820px) {

    .title-link {
      font-size: 50px;
    }
    .about-title .secret-body{
      font-size: 25px;
      line-height: 2.5rem;
      padding: 25px 15px 75px;
    }
    .about .secret-head{
      /* height: 100vh; */
      align-items: center;
      justify-content: center;
      font-size: 3rem;
    }
    .contact{
      height: 100vh;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
    }
    
  }

  @media screen and (max-width: 320px) {
    .about .secret-head{
      height: 100vh;
      align-items: center;
      justify-content: center;
      font-size: 3rem;
    }
  
  }

  
   
  
 
