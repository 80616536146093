/* video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  } */

  /* .wolf-image{
    width:100%;
    height:100%;
    object-fit: cover;

  } */





  .wolf-image {
    height: 100%;
    width: 115%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* object-fit: contain; */
  }

  .razn-hell {
    height: 100%;
    width: 115%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* object-fit: contain; */
  }

  .content {
    text-align: center;
  }

  iframe {
    max-width: 100%; /* Make sure the iframe is responsive */
    height:600; /* Auto height for responsiveness */
  }
  

.terminal {
  background-color: black;
  color: lime;
  font-family: 'Courier New', monospace;
  padding: 20px;
}

.terminal-text {
  line-height: 1.5;
}

.prompt {
  color: #00ff00; /* Green color for the prompt symbol */
}

.command {
  color: white;
}

  
  
   .hero-container {
    /* background-size: 50%; */
    /* height: 100vh;
    width: 100%; */
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
    margin-top: -5px;
  }
   
   .hero-container > h1 {
    color: #fff;
    font-size: 100px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  @media screen and (min-width: 2100px){
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
    
    .wolf-image{
      padding:0px 150px
    }
  }

  @media screen and (min-width: 1700px){
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
    
    .wolf-image{
      padding:0px 150px
    }
  }
  
  @media screen and (min-width: 1400px){
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
    .cards__container {
      width: 90%;
    }
    .wolf-image,.razn-hell{
      padding:0px 150px;
      width: 90%;
    }
    .title-link{
      font-size: 90%;
    }

    .about-title, .secret-body{
      font-size: 35px;
      margin-left: 20px;

    }
  }


  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }

  @media screen and (max-width: 820px){
    .about-title, .secret-title{
      font-size: 35px;
    margin-left: 10px;
    }
    
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
    .content {
      padding: 0 20px; 
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  }

  @media screen and (max-width: 450px) {
    .about, .contact, .secret{
      font-size: 30px;
    }

 
    

  }
   


